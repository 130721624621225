const initialState = {
	offer: null,
	dashboard_count: null,
}

export const useOfferStore = defineStore('offer', {
	state: () => ({ ...initialState }),
	actions: {
		async createOffer(params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.postOffer(params)

			if (response.success) {
				this.offer = response.result
			}

			return response
		},
		async updateOfferById(offerId, params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.postUpdateOffer(offerId, params)

			if (response.success) {
				this.offer = response.result
			}

			return response
		},
		async fetchDetailsOffer(offerId) {
			if (this.offer && this.offer.id === offerId) {
				return { success: true, result: this.offer }
			}

			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.getOffer(offerId)

			if (response.success) {
				this.offer = response.result
			}

			return response
		},
		async fetchDashboardDetails(idOffer) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.getDashboardDetails(idOffer)

			if (response.success) {
				const { work_offer, dashboard_count } = response.result
				this.offer = work_offer
				this.dashboard_count = dashboard_count
			}

			return response
		},
	},
})
