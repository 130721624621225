import {
	ID_DEVELOPER_TYPE_FRONTEND,
	ID_DEVELOPER_TYPE_BACKEND,
	ID_DEVELOPER_TYPE_DATA_ANALYST,
	ID_DEVELOPER_TYPE_MOBILE,
	ID_DEVELOPER_TYPE_DEVOS,
	ID_DEVELOPER_TYPE_QA,
	ID_DEVELOPER_TYPE_WEB3,
} from '~/utils/constants/developerType'
import { ID_AGILE_METHODOLOGIES, ID_MANAGEMENT_TOOLS, ID_PROGRAMMING_LANGUAGE } from '~/utils/constants/skillType'

const initialState = {
	timezone: [],
	cities: [],
	resources: {},
}

export const useSupportStore = defineStore('support', {
	state: () => ({ ...initialState }),
	getters: {
		getResource: (state) => {
			return (type) => {
				if (!state.resources[type]) return []
				return state.resources[type]
			}
		},
		getFindResources: (state) => {
			return (type, id) => {
				return state.resources[type] ? state.resources[type].find((item) => item.id === id) : null
			}
		},
		getFilterResources: (state) => {
			return (type, id, prop = 'id') => {
				return state.resources[type].filter((item) => item[prop] === id)
			}
		},
		getStack: (state) => {
			if (!state.resources.skills) return []

			const developerTypes = [
				ID_DEVELOPER_TYPE_FRONTEND,
				ID_DEVELOPER_TYPE_BACKEND,
				ID_DEVELOPER_TYPE_DATA_ANALYST,
				ID_DEVELOPER_TYPE_MOBILE,
				ID_DEVELOPER_TYPE_DEVOS,
				ID_DEVELOPER_TYPE_QA,
				ID_DEVELOPER_TYPE_WEB3,
			]

			return state.resources.skills.filter(
				(stack) =>
					developerTypes.includes(stack.developer_type?.id) ||
					stack?.skill_types?.some((type) => type.id === ID_PROGRAMMING_LANGUAGE),
			)
		},
		getOtherSkills: (state) => {
			if (!state.resources.skills) return []
			const developerTypes = [ID_AGILE_METHODOLOGIES, ID_MANAGEMENT_TOOLS]
			return state.resources.skills.filter((stack) => developerTypes.includes(stack.skill_type_id))
		},
	},
	actions: {
		async fetchSupport(keys = []) {
			const { $repository } = useNuxtApp()
			const storeKeys = Object.keys(this.resources)
			const newKeys = keys.filter((key) => !storeKeys.includes(key))

			if (storeKeys.length && newKeys.length === 0) return

			const response = await $repository.common.support.getSupportResources({
				keys: newKeys,
			})

			const newResources = { ...this.resources, ...response.result }

			if (response.success) {
				this.resources = newResources
			}

			return response
		},
	},
})
