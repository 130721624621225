import { default as billingMiSw0xCZlXMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/billing.vue?macro=true";
import { default as companyTVUedD0jkyMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/company.vue?macro=true";
import { default as plan9HbXeKDX7wMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/plan.vue?macro=true";
import { default as indexuXBDi8bIU6Meta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/account/index.vue?macro=true";
import { default as _91token_93Lhtfpk7TyGMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/auth/reset-password/[token].vue?macro=true";
import { default as indexJAkBlTWTOSMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/auth/reset-password/index.vue?macro=true";
import { default as indexstVYekzEuxMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/[idcandidate]/index.vue?macro=true";
import { default as best_45by_45roleK3OW9N3EzJMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/best-by-role.vue?macro=true";
import { default as indexEPeuqWwlEeMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/index.vue?macro=true";
import { default as scheduled1Je3fb1UELMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/demo/scheduled.vue?macro=true";
import { default as indexin15kqW0dVMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/index.vue?macro=true";
import { default as loginDTx5Uji1NgMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/login.vue?macro=true";
import { default as logout0EG1AP7J6fMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/logout.vue?macro=true";
import { default as indexYiJ6IQsNPiMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offer/create/index.vue?macro=true";
import { default as createkxEp7nLlXZMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offer/create.vue?macro=true";
import { default as import3VtOo8yGmiMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offer/import.vue?macro=true";
import { default as detailsRdnM3rDBA7Meta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue?macro=true";
import { default as indexHA1wQ9m1ZrMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue?macro=true";
import { default as technical_45requirementsy7vo89e2sXMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue?macro=true";
import { default as editQvsZlLA6f6Meta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit.vue?macro=true";
import { default as detailsfyFAJ5grbEMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/details.vue?macro=true";
import { default as index87AqhYeBEUMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/index.vue?macro=true";
import { default as pipelinedaXHbahq95Meta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue?macro=true";
import { default as searchKXAV4kN0CAMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/search.vue?macro=true";
import { default as indexpk6OWmoZPhMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index.vue?macro=true";
import { default as password_45reseteY9AFedeNLMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/password-reset.vue?macro=true";
import { default as password_45setupEN1bonpZjIMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/register/password-setup.vue?macro=true";
import { default as profile_45setupCA1jnm7R5VMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/register/profile-setup.vue?macro=true";
import { default as buyBSZ2TTN2TuMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/buy.vue?macro=true";
import { default as payment_45methodeyEaXi5AdYMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/payment-method.vue?macro=true";
import { default as successful9qcpw9pCG2Meta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/successful.vue?macro=true";
import { default as updatehZOgCxXSPiMeta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/update.vue?macro=true";
import { default as indexT9TMO7NIQ5Meta } from "/codebuild/output/src3302789168/src/frontend-hire/pages/users/index.vue?macro=true";
import { default as nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta } from "/codebuild/output/src3302789168/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: indexuXBDi8bIU6Meta?.name ?? "account___es",
    path: indexuXBDi8bIU6Meta?.path ?? "/account",
    meta: indexuXBDi8bIU6Meta || {},
    alias: indexuXBDi8bIU6Meta?.alias || [],
    redirect: indexuXBDi8bIU6Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: billingMiSw0xCZlXMeta?.name ?? "account-index-billing___es",
    path: billingMiSw0xCZlXMeta?.path ?? "billing",
    meta: billingMiSw0xCZlXMeta || {},
    alias: billingMiSw0xCZlXMeta?.alias || [],
    redirect: billingMiSw0xCZlXMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/billing.vue").then(m => m.default || m)
  },
  {
    name: companyTVUedD0jkyMeta?.name ?? "account-index-company___es",
    path: companyTVUedD0jkyMeta?.path ?? "company",
    meta: companyTVUedD0jkyMeta || {},
    alias: companyTVUedD0jkyMeta?.alias || [],
    redirect: companyTVUedD0jkyMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/company.vue").then(m => m.default || m)
  },
  {
    name: plan9HbXeKDX7wMeta?.name ?? "account-index-plan___es",
    path: plan9HbXeKDX7wMeta?.path ?? "plan",
    meta: plan9HbXeKDX7wMeta || {},
    alias: plan9HbXeKDX7wMeta?.alias || [],
    redirect: plan9HbXeKDX7wMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexuXBDi8bIU6Meta?.name ?? "account___en",
    path: indexuXBDi8bIU6Meta?.path ?? "/en/account",
    meta: indexuXBDi8bIU6Meta || {},
    alias: indexuXBDi8bIU6Meta?.alias || [],
    redirect: indexuXBDi8bIU6Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: billingMiSw0xCZlXMeta?.name ?? "account-index-billing___en",
    path: billingMiSw0xCZlXMeta?.path ?? "billing",
    meta: billingMiSw0xCZlXMeta || {},
    alias: billingMiSw0xCZlXMeta?.alias || [],
    redirect: billingMiSw0xCZlXMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/billing.vue").then(m => m.default || m)
  },
  {
    name: companyTVUedD0jkyMeta?.name ?? "account-index-company___en",
    path: companyTVUedD0jkyMeta?.path ?? "company",
    meta: companyTVUedD0jkyMeta || {},
    alias: companyTVUedD0jkyMeta?.alias || [],
    redirect: companyTVUedD0jkyMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/company.vue").then(m => m.default || m)
  },
  {
    name: plan9HbXeKDX7wMeta?.name ?? "account-index-plan___en",
    path: plan9HbXeKDX7wMeta?.path ?? "plan",
    meta: plan9HbXeKDX7wMeta || {},
    alias: plan9HbXeKDX7wMeta?.alias || [],
    redirect: plan9HbXeKDX7wMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/account/index/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91token_93Lhtfpk7TyGMeta?.name ?? "auth-reset-password-token___es",
    path: _91token_93Lhtfpk7TyGMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93Lhtfpk7TyGMeta || {},
    alias: _91token_93Lhtfpk7TyGMeta?.alias || [],
    redirect: _91token_93Lhtfpk7TyGMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Lhtfpk7TyGMeta?.name ?? "auth-reset-password-token___en",
    path: _91token_93Lhtfpk7TyGMeta?.path ?? "/en/auth/reset-password/:token()",
    meta: _91token_93Lhtfpk7TyGMeta || {},
    alias: _91token_93Lhtfpk7TyGMeta?.alias || [],
    redirect: _91token_93Lhtfpk7TyGMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexJAkBlTWTOSMeta?.name ?? "auth-reset-password___es",
    path: indexJAkBlTWTOSMeta?.path ?? "/auth/reset-password",
    meta: indexJAkBlTWTOSMeta || {},
    alias: indexJAkBlTWTOSMeta?.alias || [],
    redirect: indexJAkBlTWTOSMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexJAkBlTWTOSMeta?.name ?? "auth-reset-password___en",
    path: indexJAkBlTWTOSMeta?.path ?? "/en/auth/reset-password",
    meta: indexJAkBlTWTOSMeta || {},
    alias: indexJAkBlTWTOSMeta?.alias || [],
    redirect: indexJAkBlTWTOSMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexstVYekzEuxMeta?.name ?? "candidates-idcandidate___es",
    path: indexstVYekzEuxMeta?.path ?? "/candidates/:idcandidate()",
    meta: indexstVYekzEuxMeta || {},
    alias: indexstVYekzEuxMeta?.alias || [],
    redirect: indexstVYekzEuxMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/[idcandidate]/index.vue").then(m => m.default || m)
  },
  {
    name: indexstVYekzEuxMeta?.name ?? "candidates-idcandidate___en",
    path: indexstVYekzEuxMeta?.path ?? "/en/candidates/:idcandidate()",
    meta: indexstVYekzEuxMeta || {},
    alias: indexstVYekzEuxMeta?.alias || [],
    redirect: indexstVYekzEuxMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/[idcandidate]/index.vue").then(m => m.default || m)
  },
  {
    name: best_45by_45roleK3OW9N3EzJMeta?.name ?? "candidates-best-by-role___es",
    path: best_45by_45roleK3OW9N3EzJMeta?.path ?? "/candidates/best-by-role",
    meta: best_45by_45roleK3OW9N3EzJMeta || {},
    alias: best_45by_45roleK3OW9N3EzJMeta?.alias || [],
    redirect: best_45by_45roleK3OW9N3EzJMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/best-by-role.vue").then(m => m.default || m)
  },
  {
    name: best_45by_45roleK3OW9N3EzJMeta?.name ?? "candidates-best-by-role___en",
    path: best_45by_45roleK3OW9N3EzJMeta?.path ?? "/en/candidates/best-by-role",
    meta: best_45by_45roleK3OW9N3EzJMeta || {},
    alias: best_45by_45roleK3OW9N3EzJMeta?.alias || [],
    redirect: best_45by_45roleK3OW9N3EzJMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/best-by-role.vue").then(m => m.default || m)
  },
  {
    name: indexEPeuqWwlEeMeta?.name ?? "candidates___es",
    path: indexEPeuqWwlEeMeta?.path ?? "/candidates",
    meta: indexEPeuqWwlEeMeta || {},
    alias: indexEPeuqWwlEeMeta?.alias || [],
    redirect: indexEPeuqWwlEeMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: indexEPeuqWwlEeMeta?.name ?? "candidates___en",
    path: indexEPeuqWwlEeMeta?.path ?? "/en/candidates",
    meta: indexEPeuqWwlEeMeta || {},
    alias: indexEPeuqWwlEeMeta?.alias || [],
    redirect: indexEPeuqWwlEeMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: scheduled1Je3fb1UELMeta?.name ?? "demo-scheduled___es",
    path: scheduled1Je3fb1UELMeta?.path ?? "/demo/scheduled",
    meta: scheduled1Je3fb1UELMeta || {},
    alias: scheduled1Je3fb1UELMeta?.alias || [],
    redirect: scheduled1Je3fb1UELMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/demo/scheduled.vue").then(m => m.default || m)
  },
  {
    name: scheduled1Je3fb1UELMeta?.name ?? "demo-scheduled___en",
    path: scheduled1Je3fb1UELMeta?.path ?? "/en/demo/scheduled",
    meta: scheduled1Je3fb1UELMeta || {},
    alias: scheduled1Je3fb1UELMeta?.alias || [],
    redirect: scheduled1Je3fb1UELMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/demo/scheduled.vue").then(m => m.default || m)
  },
  {
    name: indexin15kqW0dVMeta?.name ?? "index___es",
    path: indexin15kqW0dVMeta?.path ?? "/",
    meta: indexin15kqW0dVMeta || {},
    alias: indexin15kqW0dVMeta?.alias || [],
    redirect: indexin15kqW0dVMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexin15kqW0dVMeta?.name ?? "index___en",
    path: indexin15kqW0dVMeta?.path ?? "/en",
    meta: indexin15kqW0dVMeta || {},
    alias: indexin15kqW0dVMeta?.alias || [],
    redirect: indexin15kqW0dVMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginDTx5Uji1NgMeta?.name ?? "login___es",
    path: loginDTx5Uji1NgMeta?.path ?? "/login",
    meta: loginDTx5Uji1NgMeta || {},
    alias: loginDTx5Uji1NgMeta?.alias || [],
    redirect: loginDTx5Uji1NgMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginDTx5Uji1NgMeta?.name ?? "login___en",
    path: loginDTx5Uji1NgMeta?.path ?? "/en/login",
    meta: loginDTx5Uji1NgMeta || {},
    alias: loginDTx5Uji1NgMeta?.alias || [],
    redirect: loginDTx5Uji1NgMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logout0EG1AP7J6fMeta?.name ?? "logout___es",
    path: logout0EG1AP7J6fMeta?.path ?? "/logout",
    meta: logout0EG1AP7J6fMeta || {},
    alias: logout0EG1AP7J6fMeta?.alias || [],
    redirect: logout0EG1AP7J6fMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logout0EG1AP7J6fMeta?.name ?? "logout___en",
    path: logout0EG1AP7J6fMeta?.path ?? "/en/logout",
    meta: logout0EG1AP7J6fMeta || {},
    alias: logout0EG1AP7J6fMeta?.alias || [],
    redirect: logout0EG1AP7J6fMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: createkxEp7nLlXZMeta?.name ?? undefined,
    path: createkxEp7nLlXZMeta?.path ?? "/offer/create",
    meta: createkxEp7nLlXZMeta || {},
    alias: createkxEp7nLlXZMeta?.alias || [],
    redirect: createkxEp7nLlXZMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offer/create.vue").then(m => m.default || m),
    children: [
  {
    name: indexYiJ6IQsNPiMeta?.name ?? "offer-create___es",
    path: indexYiJ6IQsNPiMeta?.path ?? "",
    meta: indexYiJ6IQsNPiMeta || {},
    alias: indexYiJ6IQsNPiMeta?.alias || [],
    redirect: indexYiJ6IQsNPiMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offer/create/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: createkxEp7nLlXZMeta?.name ?? undefined,
    path: createkxEp7nLlXZMeta?.path ?? "/en/offer/create",
    meta: createkxEp7nLlXZMeta || {},
    alias: createkxEp7nLlXZMeta?.alias || [],
    redirect: createkxEp7nLlXZMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offer/create.vue").then(m => m.default || m),
    children: [
  {
    name: indexYiJ6IQsNPiMeta?.name ?? "offer-create___en",
    path: indexYiJ6IQsNPiMeta?.path ?? "",
    meta: indexYiJ6IQsNPiMeta || {},
    alias: indexYiJ6IQsNPiMeta?.alias || [],
    redirect: indexYiJ6IQsNPiMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offer/create/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: import3VtOo8yGmiMeta?.name ?? "offer-import___es",
    path: import3VtOo8yGmiMeta?.path ?? "/offer/import",
    meta: import3VtOo8yGmiMeta || {},
    alias: import3VtOo8yGmiMeta?.alias || [],
    redirect: import3VtOo8yGmiMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offer/import.vue").then(m => m.default || m)
  },
  {
    name: import3VtOo8yGmiMeta?.name ?? "offer-import___en",
    path: import3VtOo8yGmiMeta?.path ?? "/en/offer/import",
    meta: import3VtOo8yGmiMeta || {},
    alias: import3VtOo8yGmiMeta?.alias || [],
    redirect: import3VtOo8yGmiMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offer/import.vue").then(m => m.default || m)
  },
  {
    name: editQvsZlLA6f6Meta?.name ?? undefined,
    path: editQvsZlLA6f6Meta?.path ?? "/offers/:idoffer()/edit",
    meta: editQvsZlLA6f6Meta || {},
    alias: editQvsZlLA6f6Meta?.alias || [],
    redirect: editQvsZlLA6f6Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: detailsRdnM3rDBA7Meta?.name ?? "offers-idoffer-edit-details___es",
    path: detailsRdnM3rDBA7Meta?.path ?? "details",
    meta: detailsRdnM3rDBA7Meta || {},
    alias: detailsRdnM3rDBA7Meta?.alias || [],
    redirect: detailsRdnM3rDBA7Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue").then(m => m.default || m)
  },
  {
    name: indexHA1wQ9m1ZrMeta?.name ?? "offers-idoffer-edit___es",
    path: indexHA1wQ9m1ZrMeta?.path ?? "",
    meta: indexHA1wQ9m1ZrMeta || {},
    alias: indexHA1wQ9m1ZrMeta?.alias || [],
    redirect: indexHA1wQ9m1ZrMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45requirementsy7vo89e2sXMeta?.name ?? "offers-idoffer-edit-technical-requirements___es",
    path: technical_45requirementsy7vo89e2sXMeta?.path ?? "technical-requirements",
    meta: technical_45requirementsy7vo89e2sXMeta || {},
    alias: technical_45requirementsy7vo89e2sXMeta?.alias || [],
    redirect: technical_45requirementsy7vo89e2sXMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editQvsZlLA6f6Meta?.name ?? undefined,
    path: editQvsZlLA6f6Meta?.path ?? "/en/offers/:idoffer()/edit",
    meta: editQvsZlLA6f6Meta || {},
    alias: editQvsZlLA6f6Meta?.alias || [],
    redirect: editQvsZlLA6f6Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: detailsRdnM3rDBA7Meta?.name ?? "offers-idoffer-edit-details___en",
    path: detailsRdnM3rDBA7Meta?.path ?? "details",
    meta: detailsRdnM3rDBA7Meta || {},
    alias: detailsRdnM3rDBA7Meta?.alias || [],
    redirect: detailsRdnM3rDBA7Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue").then(m => m.default || m)
  },
  {
    name: indexHA1wQ9m1ZrMeta?.name ?? "offers-idoffer-edit___en",
    path: indexHA1wQ9m1ZrMeta?.path ?? "",
    meta: indexHA1wQ9m1ZrMeta || {},
    alias: indexHA1wQ9m1ZrMeta?.alias || [],
    redirect: indexHA1wQ9m1ZrMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45requirementsy7vo89e2sXMeta?.name ?? "offers-idoffer-edit-technical-requirements___en",
    path: technical_45requirementsy7vo89e2sXMeta?.path ?? "technical-requirements",
    meta: technical_45requirementsy7vo89e2sXMeta || {},
    alias: technical_45requirementsy7vo89e2sXMeta?.alias || [],
    redirect: technical_45requirementsy7vo89e2sXMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexpk6OWmoZPhMeta?.name ?? undefined,
    path: indexpk6OWmoZPhMeta?.path ?? "/offers/:idoffer()",
    meta: indexpk6OWmoZPhMeta || {},
    alias: indexpk6OWmoZPhMeta?.alias || [],
    redirect: indexpk6OWmoZPhMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsfyFAJ5grbEMeta?.name ?? "offers-idoffer-index-details___es",
    path: detailsfyFAJ5grbEMeta?.path ?? "details",
    meta: detailsfyFAJ5grbEMeta || {},
    alias: detailsfyFAJ5grbEMeta?.alias || [],
    redirect: detailsfyFAJ5grbEMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/details.vue").then(m => m.default || m)
  },
  {
    name: index87AqhYeBEUMeta?.name ?? "offers-idoffer-index___es",
    path: index87AqhYeBEUMeta?.path ?? "",
    meta: index87AqhYeBEUMeta || {},
    alias: index87AqhYeBEUMeta?.alias || [],
    redirect: index87AqhYeBEUMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/index.vue").then(m => m.default || m)
  },
  {
    name: pipelinedaXHbahq95Meta?.name ?? "offers-idoffer-index-pipeline___es",
    path: pipelinedaXHbahq95Meta?.path ?? "pipeline",
    meta: pipelinedaXHbahq95Meta || {},
    alias: pipelinedaXHbahq95Meta?.alias || [],
    redirect: pipelinedaXHbahq95Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue").then(m => m.default || m)
  },
  {
    name: searchKXAV4kN0CAMeta?.name ?? "offers-idoffer-index-search___es",
    path: searchKXAV4kN0CAMeta?.path ?? "search",
    meta: searchKXAV4kN0CAMeta || {},
    alias: searchKXAV4kN0CAMeta?.alias || [],
    redirect: searchKXAV4kN0CAMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexpk6OWmoZPhMeta?.name ?? undefined,
    path: indexpk6OWmoZPhMeta?.path ?? "/en/offers/:idoffer()",
    meta: indexpk6OWmoZPhMeta || {},
    alias: indexpk6OWmoZPhMeta?.alias || [],
    redirect: indexpk6OWmoZPhMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsfyFAJ5grbEMeta?.name ?? "offers-idoffer-index-details___en",
    path: detailsfyFAJ5grbEMeta?.path ?? "details",
    meta: detailsfyFAJ5grbEMeta || {},
    alias: detailsfyFAJ5grbEMeta?.alias || [],
    redirect: detailsfyFAJ5grbEMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/details.vue").then(m => m.default || m)
  },
  {
    name: index87AqhYeBEUMeta?.name ?? "offers-idoffer-index___en",
    path: index87AqhYeBEUMeta?.path ?? "",
    meta: index87AqhYeBEUMeta || {},
    alias: index87AqhYeBEUMeta?.alias || [],
    redirect: index87AqhYeBEUMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/index.vue").then(m => m.default || m)
  },
  {
    name: pipelinedaXHbahq95Meta?.name ?? "offers-idoffer-index-pipeline___en",
    path: pipelinedaXHbahq95Meta?.path ?? "pipeline",
    meta: pipelinedaXHbahq95Meta || {},
    alias: pipelinedaXHbahq95Meta?.alias || [],
    redirect: pipelinedaXHbahq95Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue").then(m => m.default || m)
  },
  {
    name: searchKXAV4kN0CAMeta?.name ?? "offers-idoffer-index-search___en",
    path: searchKXAV4kN0CAMeta?.path ?? "search",
    meta: searchKXAV4kN0CAMeta || {},
    alias: searchKXAV4kN0CAMeta?.alias || [],
    redirect: searchKXAV4kN0CAMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/offers/[idoffer]/index/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: password_45reseteY9AFedeNLMeta?.name ?? "password-reset___es",
    path: password_45reseteY9AFedeNLMeta?.path ?? "/password-reset",
    meta: password_45reseteY9AFedeNLMeta || {},
    alias: password_45reseteY9AFedeNLMeta?.alias || [],
    redirect: password_45reseteY9AFedeNLMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45reseteY9AFedeNLMeta?.name ?? "password-reset___en",
    path: password_45reseteY9AFedeNLMeta?.path ?? "/en/password-reset",
    meta: password_45reseteY9AFedeNLMeta || {},
    alias: password_45reseteY9AFedeNLMeta?.alias || [],
    redirect: password_45reseteY9AFedeNLMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45setupEN1bonpZjIMeta?.name ?? "register-password-setup___es",
    path: password_45setupEN1bonpZjIMeta?.path ?? "/register/password-setup",
    meta: password_45setupEN1bonpZjIMeta || {},
    alias: password_45setupEN1bonpZjIMeta?.alias || [],
    redirect: password_45setupEN1bonpZjIMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/register/password-setup.vue").then(m => m.default || m)
  },
  {
    name: password_45setupEN1bonpZjIMeta?.name ?? "register-password-setup___en",
    path: password_45setupEN1bonpZjIMeta?.path ?? "/en/register/password-setup",
    meta: password_45setupEN1bonpZjIMeta || {},
    alias: password_45setupEN1bonpZjIMeta?.alias || [],
    redirect: password_45setupEN1bonpZjIMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/register/password-setup.vue").then(m => m.default || m)
  },
  {
    name: profile_45setupCA1jnm7R5VMeta?.name ?? "register-profile-setup___es",
    path: profile_45setupCA1jnm7R5VMeta?.path ?? "/register/profile-setup",
    meta: profile_45setupCA1jnm7R5VMeta || {},
    alias: profile_45setupCA1jnm7R5VMeta?.alias || [],
    redirect: profile_45setupCA1jnm7R5VMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/register/profile-setup.vue").then(m => m.default || m)
  },
  {
    name: profile_45setupCA1jnm7R5VMeta?.name ?? "register-profile-setup___en",
    path: profile_45setupCA1jnm7R5VMeta?.path ?? "/en/register/profile-setup",
    meta: profile_45setupCA1jnm7R5VMeta || {},
    alias: profile_45setupCA1jnm7R5VMeta?.alias || [],
    redirect: profile_45setupCA1jnm7R5VMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/register/profile-setup.vue").then(m => m.default || m)
  },
  {
    name: buyBSZ2TTN2TuMeta?.name ?? "subscription-buy___es",
    path: buyBSZ2TTN2TuMeta?.path ?? "/subscription/buy",
    meta: buyBSZ2TTN2TuMeta || {},
    alias: buyBSZ2TTN2TuMeta?.alias || [],
    redirect: buyBSZ2TTN2TuMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/buy.vue").then(m => m.default || m)
  },
  {
    name: buyBSZ2TTN2TuMeta?.name ?? "subscription-buy___en",
    path: buyBSZ2TTN2TuMeta?.path ?? "/en/subscription/buy",
    meta: buyBSZ2TTN2TuMeta || {},
    alias: buyBSZ2TTN2TuMeta?.alias || [],
    redirect: buyBSZ2TTN2TuMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/buy.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodeyEaXi5AdYMeta?.name ?? "subscription-payment-method___es",
    path: payment_45methodeyEaXi5AdYMeta?.path ?? "/subscription/payment-method",
    meta: payment_45methodeyEaXi5AdYMeta || {},
    alias: payment_45methodeyEaXi5AdYMeta?.alias || [],
    redirect: payment_45methodeyEaXi5AdYMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/payment-method.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodeyEaXi5AdYMeta?.name ?? "subscription-payment-method___en",
    path: payment_45methodeyEaXi5AdYMeta?.path ?? "/en/subscription/payment-method",
    meta: payment_45methodeyEaXi5AdYMeta || {},
    alias: payment_45methodeyEaXi5AdYMeta?.alias || [],
    redirect: payment_45methodeyEaXi5AdYMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/payment-method.vue").then(m => m.default || m)
  },
  {
    name: successful9qcpw9pCG2Meta?.name ?? "subscription-successful___es",
    path: successful9qcpw9pCG2Meta?.path ?? "/subscription/successful",
    meta: successful9qcpw9pCG2Meta || {},
    alias: successful9qcpw9pCG2Meta?.alias || [],
    redirect: successful9qcpw9pCG2Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/successful.vue").then(m => m.default || m)
  },
  {
    name: successful9qcpw9pCG2Meta?.name ?? "subscription-successful___en",
    path: successful9qcpw9pCG2Meta?.path ?? "/en/subscription/successful",
    meta: successful9qcpw9pCG2Meta || {},
    alias: successful9qcpw9pCG2Meta?.alias || [],
    redirect: successful9qcpw9pCG2Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/successful.vue").then(m => m.default || m)
  },
  {
    name: updatehZOgCxXSPiMeta?.name ?? "subscription-update___es",
    path: updatehZOgCxXSPiMeta?.path ?? "/subscription/update",
    meta: updatehZOgCxXSPiMeta || {},
    alias: updatehZOgCxXSPiMeta?.alias || [],
    redirect: updatehZOgCxXSPiMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/update.vue").then(m => m.default || m)
  },
  {
    name: updatehZOgCxXSPiMeta?.name ?? "subscription-update___en",
    path: updatehZOgCxXSPiMeta?.path ?? "/en/subscription/update",
    meta: updatehZOgCxXSPiMeta || {},
    alias: updatehZOgCxXSPiMeta?.alias || [],
    redirect: updatehZOgCxXSPiMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/subscription/update.vue").then(m => m.default || m)
  },
  {
    name: indexT9TMO7NIQ5Meta?.name ?? "users___es",
    path: indexT9TMO7NIQ5Meta?.path ?? "/users",
    meta: indexT9TMO7NIQ5Meta || {},
    alias: indexT9TMO7NIQ5Meta?.alias || [],
    redirect: indexT9TMO7NIQ5Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexT9TMO7NIQ5Meta?.name ?? "users___en",
    path: indexT9TMO7NIQ5Meta?.path ?? "/en/users",
    meta: indexT9TMO7NIQ5Meta || {},
    alias: indexT9TMO7NIQ5Meta?.alias || [],
    redirect: indexT9TMO7NIQ5Meta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.path ?? "/profile",
    meta: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta || {},
    alias: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.alias || [],
    redirect: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.path ?? "/comprar-suscripcion",
    meta: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta || {},
    alias: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.alias || [],
    redirect: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.path ?? "/offers",
    meta: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta || {},
    alias: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.alias || [],
    redirect: nuxt_643_4610_463__64types_43node_6420_4611_4620_eslint_648_4657_460_rollup_644_4612_460_typescript_645_463_463_vite_645_461_464_vue_45tsc_641_468uk4OZ7UhJKMeta?.redirect,
    component: () => import("/codebuild/output/src3302789168/src/frontend-hire/node_modules/.pnpm/nuxt@3.10.3_@types+node@20.11.20_eslint@8.57.0_rollup@4.12.0_typescript@5.3.3_vite@5.1.4_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]