import { useRequest } from '~/repositories'

export const getOffer = (offerId) => {
	return useRequest('get', `/v3/company/offers/${offerId}/detail`)
}

export const postOffer = (params) => {
	return useRequest('post', `/v3/company/offers`, params)
}

export const postUpdateOffer = (offerId, params) => {
	return useRequest('post', `/v3/company/offers/${offerId}`, params)
}

export const putOffer = (offerId, params) => {
	return useRequest('put', `/v3/company/offers/${offerId}`, params)
}

export const putOfferProfesionalProfile = (offerId, params) => {
	return useRequest('put', `/v3/company/offers/${offerId}/professional-profile`, params)
}

export const putOfferCompanyProfile = (offerId, params) => {
	return useRequest('put', `/v3/company/offers/${offerId}/company-profile`, params)
}

export const putOfferSendForReview = (offerId) => {
	return useRequest('put', `/v3/company/offers/${offerId}/send-for-review`)
}

export const deleteOffer = (offerId) => {
	return useRequest('delete', `/v3/company/offers/${offerId}`)
}

export const getListCandidatesByIdOffer = (idOffer, params) => {
	return useRequest('get', `/v3/company/offers/${idOffer}`, { params })
}

export const getCandidate = (idOffer, idCandidate) => {
	return useRequest('get', `/v3/company/offers/${idOffer}/profile/${idCandidate}/`)
}

export const getPreviousAndNextCandidate = (idOffer, idCandidate) => {
	return useRequest('get', `/v3/company/offers/${idOffer}/profile/${idCandidate}/previous-and-next-candidate`)
}

export const getReviewStatus = (idOffer) => {
	return useRequest('get', `/v3/company/offers/${idOffer}/review-status`)
}

export const putCandidateStatus = (idOffer, idCandidate, params) => {
	return useRequest('put', `/v3/company/offers/${idOffer}/profile/${idCandidate}/status`, params)
}

export const putChangeOfferStatus = (offerId, params) => {
	return useRequest('put', `/v3/company/offers/${offerId}/change-status`, params)
}

// Lista de ofertas
export const getFiltersOffers = () => {
	return useRequest('get', `/v3/company/offers/filters`)
}

export const getOffers = (params) => {
	return useRequest('get', `/v3/company/offers`, { params })
}

export const getDashboardCount = () => {
	return useRequest('get', `/v3/company/offers/dashboard-counts`)
}

export const getDashboardDetails = (idOffer) => {
	return useRequest('get', `/v3/company/offers/${idOffer}/dashboard/detail/`)
}

export const getCandidateWorkOffers = (id) => {
	return useRequest('get', `/v3/company/offers/candidate/work-offers/${id}`)
}

export const postCandidateOffers = (id, params) => {
	return useRequest('post', `/v3/company/offers/add-candidate/${id}/to-work-offers`, params)
}

// Notas de la empresa
export const getCandidateNotes = (idOffer, idCandidate) => {
	return useRequest('get', `/v3/company/offers/${idOffer}/profile/${idCandidate}/company-notes`)
}

export const postCandidateNote = (idOffer, idCandidate, params) => {
	return useRequest('post', `/v3/company/offers/${idOffer}/profile/${idCandidate}/company-notes`, params)
}

export default {
	getOffer,
	postOffer,
	postUpdateOffer,
	putOffer,
	putOfferProfesionalProfile,
	putOfferCompanyProfile,
	putOfferSendForReview,
	deleteOffer,
	getListCandidatesByIdOffer,
	getCandidate,
	getPreviousAndNextCandidate,
	getFiltersOffers,
	getOffers,
	getDashboardCount,
	getCandidateWorkOffers,
	postCandidateOffers,
	getDashboardDetails,
	getCandidateNotes,
	postCandidateNote,
	getReviewStatus,
	putCandidateStatus,
	putChangeOfferStatus,
}
