import pagesEvents from '~/tracking/events/pagesEvents'
import authEvents from '~/tracking/events/authEvents'
import userEvents from '~/tracking/events/userEvents'
import profileEvents from '~/tracking/events/profileEvents'
import searchToolsEvents from '~/tracking/events/searchToolEvents'
import talentEvents from '~/tracking/events/talentEvents'
import offersEvents from '~/tracking/events/offersEvents'
import candidateEvents from '~/tracking/events/candidateEvents'
import notificationsEvents from '~/tracking/events/notificationsEvents'
import calendlyEvents from '~/tracking/events/calendlyEvents'
import hotjarEvents from '~/tracking/events/hotjarEvents'
import paymentsEvents from '~/tracking/events/paymentsEvents'
import billingEvents from '~/tracking/events/billingEvents'
import upsellingEvents from '~/tracking/events/upsellingEvents'
import calculatorEvents from '~/tracking/events/calculatorEvents'

export default {
	page: pagesEvents,
	auth: authEvents,
	user: userEvents,
	profile: profileEvents,
	searchTool: searchToolsEvents,
	talent: talentEvents,
	offer: offersEvents,
	candidate: candidateEvents,
	notifications: notificationsEvents,
	calendly: calendlyEvents,
	hotjar: hotjarEvents,
	payments: paymentsEvents,
	billing: billingEvents,
	upselling: upsellingEvents,
	calculator: calculatorEvents,
}
