import { useRequest } from '~/repositories/index.js'

export const postNewSubscription = (params) => {
	return useRequest('post', '/v4/payment/subscription/hire/new', params)
}

export const getPaymentsMethods = () => {
	return useRequest('get', '/v4/payment/subscription/hire/payments-methods')
}

export const postAddPaymentMethod = (params) => {
	return useRequest('post', '/v4/payment/subscription/hire/payment-method', params)
}

export const postSetDefaultPaymentMethod = (params) => {
	return useRequest('post', '/v4/payment/subscription/hire/payment-method/default', params)
}

export const deletePaymentsMethods = (params) => {
	return useRequest('delete', '/v4/payment/subscription/hire/payment-method', { params })
}

export const getPaymentHistory = (params) => {
	return useRequest('get', '/v4/payment/subscription/hire/payment-history', params)
}

export const postDowngradeSubscription = (params) => {
	return useRequest('post', '/v4/payment/subscription/hire/cancel', params)
}

export const getDowngradeReasonsSubscription = () => {
	return useRequest('get', '/v4/payment/subscription/hire/cancel-reasons')
}

export const postUpgradeSubscription = (params) => {
	return useRequest('post', '/v4/payment/subscription/hire/upgrade', params)
}

export const postValidatePromotionCode = (params) => {
	return useRequest('post', '/v4/payment/subscription/hire/validate-promotion-code', params)
}

export default {
	postNewSubscription,
	postAddPaymentMethod,
	deletePaymentsMethods,
	postDowngradeSubscription,
	postUpgradeSubscription,
	getPaymentsMethods,
	getPaymentHistory,
	postSetDefaultPaymentMethod,
	getDowngradeReasonsSubscription,
	postValidatePromotionCode,
}
