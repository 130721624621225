import tracer from '~/tracking/provider'
import $tracking from '~/tracking/utils/storage'
import { useAuthStore } from '~/store/auth'
import { useOfferStore } from '~/store/offer/offer'

const businessParams = () => {
	const $auth = useAuthStore()
	const { user } = $auth

	if (!user || !user.business || !user.business.active_subscription) return {}

	const {
		name: business_name,
		active_subscription: { label: plan_type },
	} = user.business

	return { business_name, plan_type }
}

export default {
	offerCreationView(creation_origin = 'Mis Ofertas') {
		const { plan_type, business_name } = businessParams()
		const utms = useCookie('talently.utm')
		const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } = utms.value || {}
		const initiated_at = new Date().toISOString()
		const params = {
			plan_type,
			business_name,
			utm_source,
			utm_medium,
			utm_campaign,
			utm_content,
			utm_term,
			creation_origin,
			initiated_at,
		}

		tracer.track('offer_creation_view', params)
	},

	offerCreatedIA(success_creation, fields) {
		const { plan_type, business_name } = businessParams()
		const created_at = new Date().toISOString()
		const pre_filled = []
		const no_pre_filled = []

		for (const field in fields) {
			if (fields[field] === null || fields[field] === '') {
				no_pre_filled.push(field)
			} else {
				pre_filled.push(field)
			}
		}
		const params = {
			plan_type,
			business_name,
			success_creation,
			type_error: '',
			count_pre_filled: pre_filled.length,
			pre_filled,
			no_pre_filled,
			created_at,
		}

		tracer.track('offer_created_ia', params)
	},

	offerCreatedManual(success_creation) {
		const { plan_type, business_name } = businessParams()
		const created_at = new Date().toISOString()
		const params = {
			plan_type,
			business_name,
			success_creation,
			created_at,
		}

		tracer.track('offer_created_manual', params)
	},

	offerCreated(success_load, form, fromAI) {
		const { plan_type, business_name } = businessParams()
		const created_at = new Date().toISOString()
		const type_creation_offer = fromAI ? 'ia' : 'manual'
		const params = {
			plan_type,
			business_name,
			success_load,
			type_creation_offer,
			form,
			created_at,
		}

		tracer.track('offer_created', params)
	},

	offerCreationBlock1Completed(success_load, form, fromAI) {
		const { plan_type, business_name } = businessParams()
		const created_at = new Date().toISOString()
		const information_block_1 = {
			name: form.name,
			vacancy_qty: form.vacancy_qty,
			work_offer_urgency_id: form.work_offer_urgency_id,
		}
		const type_creation_offer = fromAI ? 'ia' : 'manual'
		const params = {
			plan_type,
			business_name,
			success_load,
			information_block_1,
			type_creation_offer,
			created_at,
		}

		tracer.track('offer_creation_block_1_completed', params)
	},

	offerCreationBlock2Completed(success_load, form, fromAI) {
		const { plan_type, business_name } = businessParams()
		const created_at = new Date().toISOString()
		const information_block_2 = {
			experience: form.min_years_experience,
			english_level: form.language_proficiency_id,
			modality: form.work_modality_id,
		}
		const skills = form.stacks
		const offer_name = form.name
		const salary = [form.salary_min, form.salary_max]
		const type_creation_offer = fromAI ? 'ia' : 'manual'
		const skillParams = {}
		skills.forEach((skill, index) => {
			const key = `skill_${index + 1}`
			skillParams[key] = {
				id: skill.skill_id,
				is_required: skill.is_required,
				experience_level: skill.competency_id_by_talent,
			}
		})
		const params = {
			plan_type,
			business_name,
			success_load,
			information_block_2,
			type_creation_offer,
			offer_name,
			salary,
			...skillParams,
			created_at,
		}

		tracer.track('offer_creation_block_2_completed', params)
	},

	offerCreationBlock3Completed(success_load, form, fromAI, fieldsFromIA) {
		const { plan_type, business_name } = businessParams()
		const created_at = new Date().toISOString()
		const type_creation_offer = fromAI ? 'ia' : 'manual'
		const getFieldInfo = (field) => {
			const source = fieldsFromIA[field] ? 'ia' : 'manual'
			return form[field] ? source : 'nulo'
		}
		const requirements_info = getFieldInfo('requirement')
		const responsabilities_info = getFieldInfo('responsibility')
		const benefits_info = getFieldInfo('benefits')
		const params = {
			plan_type,
			business_name,
			success_load,
			type_creation_offer,
			requirements_info,
			responsabilities_info,
			benefits_info,
			created_at,
		}

		tracer.track('offer_creation_block_3_completed', params)
	},

	helpButtonClicked(step) {
		const { plan_type, business_name } = businessParams()
		const helped_at = new Date().toISOString()
		const params = {
			plan_type,
			business_name,
			helped_at,
			step,
		}

		tracer.track('help_button_clicked', params)
	},

	draftOfferSaved() {
		const { plan_type, business_name } = businessParams()
		const saved_at = new Date().toISOString()
		const params = {
			plan_type,
			business_name,
			saved_at,
		}

		tracer.track('draft_offer_saved', params)
	},

	async offerManagerView() {
		const { user } = useAuthStore()
		const { offer } = useOfferStore()

		const userMixpanel = await mixpanelGetUser(user.email)
		const { first_view_offer } = $tracking.storage.get('user')

		if (!userMixpanel) return

		const properties = {
			first_view_offer: true,
		}

		tracer.identify(user.email, properties)
		$tracking.storage.set('user', properties)

		const params = {
			offer_status: offer.status_label,
			subscription_plan: user.business.active_subscription.label,
			new_user: !userMixpanel.$properties?.first_view_offer || !first_view_offer,
		}

		tracer.track('offer_manager_view', params)
	},
}
