import tracer from '~/tracking/provider'
import $tracking from '~/tracking/utils/storage'
import { listErrors } from '~/tracking/utils/forms'
import { useOnboardingStore } from '~/store/onboarding'
import { useAuthStore } from '~/store/auth'

export default {
	companyProfileFormLoaded() {
		const { previous_url } = $tracking.storage.get('app')

		const params = {
			previous_url: previous_url || '',
		}

		tracer.track('company_profile_form_loaded', params)
	},

	companyProfileFormClick() {
		const params = {}

		tracer.track('company_profile_form_click', params)
	},

	companyProfileFormErrorLoaded(errors) {
		const { previous_url } = $tracking.storage.get('app')
		const { count, error_fields, raw_errors } = listErrors(errors)

		if (count === 0) return

		const params = {
			errors_amount: count,
			inputs_with_errors: error_fields,
			raw_errors,
			previous_url: previous_url || '',
		}

		tracer.track('company_profile_form_error_loaded', params)
	},

	registroB2bCompleto() {
		tracer.track('registro-b2b-completo')
	},

	async onboardingComplete() {
		const { getProgress } = useOnboardingStore()
		const { user } = useAuthStore()

		const userMixpanel = await mixpanelGetUser(user.email)
		const { onboarding_complete } = $tracking.storage.get('user')

		if (!userMixpanel) return

		if (getProgress === 50) {
			if (userMixpanel.$properties?.onboarding_complete || onboarding_complete) return

			const properties = {
				onboarding_complete: true,
			}

			tracer.track('onboarding_complete')
			tracer.identify(user.email, properties)
			$tracking.storage.set('user', properties)
		}
	},
}
