import commonSupportRepository from '~/repositories/common/supportRepository'

import authRepository from '~/repositories/v3/company/authRepository'
import workOfferRepository from '~/repositories/v3/company/workOfferRepository'
import candidateRepository from '~/repositories/v3/company/candidateRepository'
import businessRepository from '~/repositories/v3/company/businessRepository'
import interviewRepository from '~/repositories/v3/company/interviewsRepository'
import hireAuthRepository from '~/repositories/v3/hire/authRepository'
import hireRepository from '~/repositories/v3/hire/index.js'
import companyRepository from '~/repositories/v3/company/companyRepository'

import businessesRepository from '~/repositories/v4/hire/bussinessesRepository'
import businessUsersRepository from '~/repositories/v4/hire/bussinessUsersRepository'
import candidatesRepository from '~/repositories/v4/hire/candidatesRepository'
import offersRepository from '~/repositories/v4/hire/offersRepository'
import notificationsRepository from '~/repositories/v4/hire/notificationsRepository'
import carouselRepository from '~/repositories/v4/hire/carouselRepository'
import paymentsRepository from '~/repositories/v4/hire/paymentsRepository'
import hireV4Repository from '~/repositories/v4/hire/index.js'
import workOfferV4Repository from '~/repositories/v4/company/workOfferRepository'

let useFetchHire = null

export default ($fetchHire) => {
	useFetchHire = $fetchHire

	return {
		common: {
			support: commonSupportRepository,
		},
		v3: {
			company: {
				auth: authRepository,
				offers: workOfferRepository,
				candidate: candidateRepository,
				business: businessRepository,
				interviews: interviewRepository,
				...companyRepository,
			},
			hire: {
				...hireRepository,
				auth: hireAuthRepository,
			},
		},
		v4: {
			company: {
				offers: workOfferV4Repository,
			},
			hire: {
				...hireV4Repository,
				payment: paymentsRepository,
				businesses: businessesRepository,
				businessUsers: businessUsersRepository,
				candidates: candidatesRepository,
				offers: offersRepository,
				notifications: notificationsRepository,
				carousel: carouselRepository,
			},
		},
	}
}

export function useRequest(method, url, params) {
	return useFetchHire[method](url, params)
		.then(({ data }) => data)
		.catch(({ data }) => data)
}
