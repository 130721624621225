<template>
	<div id="laravel-echo"></div>
</template>

<script>
import Echo from 'laravel-echo'
// eslint-disable-next-line
import Pusher from 'pusher-js'
import { mapActions, mapState } from 'pinia'
import { useNotificationsStore } from '~/store/notifications'
import { useAuthStore } from '~/store/auth'

export default {
	name: 'Broadcasting',
	computed: {
		...mapState(useNotificationsStore, ['unReadCount']),
		...mapState(useAuthStore, ['user']),
		currentUser: {
			cache: false,
			get() {
				return this.user
			},
		},
		notifications: {
			cache: false,
			get() {
				return []
			},
		},
		isConnected: {
			cache: false,
			get() {
				return this.echo && this.echo.connector.pusher.connection.connection !== null
			},
		},
	},
	watch: {
		currentUser: {
			handler(currentUser) {
				currentUser !== null ? this.connect() : this.disconnect()
			},
		},
		isConnected: {
			handler(isConnected) {
				this.$emit('broadcasting:status', isConnected)
			},
		},
	},
	mounted() {
		this.connect()
		this.bindChannels()
	},
	methods: {
		...mapActions(useNotificationsStore, ['ADD_MORE_NOTIFICATIONS_AT_START', 'SET_STATE_NOTIFICATIONS']),
		connect() {
			if (!this.$config.public.pusher.enabled) return

			const token = useCookie('auth.business.token')
			const baseURL = `${this.$config.public.apiBase}/broadcasting/auth`
			const pusherKey = this.$config.public.pusher.key
			const pusherCluster = this.$config.public.pusher.cluster

			if (!this.echo) {
				this.echo = new Echo({
					broadcaster: 'pusher',
					key: pusherKey,
					cluster: pusherCluster,
					encrypted: true,
					authEndpoint: baseURL,
					auth: {
						headers: {
							Accept: 'application/json',
							Authorization: 'Bearer ' + token.value,
						},
					},
				})
				this.echo.connector.pusher.connection.bind('connected', (event) => {
					this.connect(event)
				})
				this.echo.connector.pusher.connection.bind('disconnected', () => {
					this.disconnect()
				})
			}

			this.echo.connector.pusher.connect()
		},
		bindChannels() {
			if (!this.$config.public.pusher.enabled) return

			this.echo
				.private(`App.Entities.BusinessUser.${this.currentUser?.id}`)
				.listen('.notification.all', (notification) => {
					this.updateNotifications(notification)
					this.playSound()
				})
		},
		updateNotifications(notification) {
			this.SET_STATE_NOTIFICATIONS({
				type: 'unReadCount',
				value: this.unReadCount + 1,
			})
			this.ADD_MORE_NOTIFICATIONS_AT_START(notification)
		},
		playSound() {
			const audio = new Audio('https://soundbible.com/mp3/Pling-KevanGC-1485374730.mp3')
			audio.play()
		},
		disconnect() {
			if (!this.$config.public.pusher.enabled) return
			if (!this.echo) return

			this.echo.disconnect()
		},
	},
}
</script>
