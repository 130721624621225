export const GET_ALL_NOTIFICATIONS = 2
export const OFFER_NOTIFICATIONS = 4

const initialState = {
	notifications: [],
	unReadCount: 0,
	pagination: null,
	filters: {
		read: null,
		order_by_date: null,
	},
	preferences: [],
}

export const useNotificationsStore = defineStore('notifications', {
	state: () => ({ ...initialState }),
	actions: {
		SET_STATE_NOTIFICATIONS({ type, value }) {
			this[type] = value
		},
		ADD_MORE_NOTIFICATIONS_AT_START(data) {
			this.notifications.unshift(...data)
		},
		SET_READ_NOTIFICATION(id) {
			const { $date } = useNuxtApp()

			const findIndex = this.notifications.findIndex((item) => item.id === id)

			if (findIndex !== -1) {
				this.notifications[findIndex].read_at = $date().format('YYYY-MM-DD HH:mm:ss')
				this.unReadCount--
			}
		},
		SET_ANCHORED_NOTIFICATION(id) {
			const findIndex = this.notifications.findIndex((item) => item.id === id)

			if (findIndex !== -1) {
				const status = this.notifications[findIndex].is_anchored
				this.notifications[findIndex].is_anchored = status ? 0 : 1
			}
		},
		// UPDATE_NOTIFICATION_PREFERENCES({ category_id, active }) {
		//   const findIndex = this.preferences.findIndex(
		//     (item) => item.category_id === category_id
		//   )
		//
		//   if (findIndex !== -1) {
		//     this.preferences[findIndex].active = active
		//   }
		// },
		// UPDATE_ALL_NOTIFICATIONS_PREFERENCES({ active }) {
		//   this.preferences.forEach((item, index) => {
		//     this.preferences[index].active = active
		//   })
		// },
		async fetchNotifications(params) {
			const { $repository } = useNuxtApp()

			const response = await $repository.v4.hire.notifications.getNotifications(params)

			if (response.success) {
				const { data, current_page, last_page } = response.result.notifications

				this.notifications.push(...data)
				this.unReadCount = response.result['unread-count']
				this.pagination = { current_page, last_page }
			}

			return response
		},
		async setReadNotification(notification) {
			const { $repository } = useNuxtApp()
			const { id, communication_id } = notification

			this.SET_READ_NOTIFICATION(id)

			return await $repository.v4.hire.notifications.postReadNotification(communication_id)
		},
		async changeAnchorNotification(id) {
			const { $repository } = useNuxtApp()
			this.SET_ANCHORED_NOTIFICATION(id)

			return await $repository.v4.hire.notifications.postAnchorNotification(id)
		},
		// async fetchNotificationPreferences() {
		//   const { $repository } = useNuxtApp()
		//   const response =
		//     await $repository.v3.match.profile.getNotificationsPreferences()
		//
		//   if (response.success) {
		//     this.SET_STATE_NOTIFICATIONS(response.result)
		//   }
		//
		//   return response
		// },
		// async updateNotificationPreferences({ commit }, params) {
		//   if (params.category_id === GET_ALL_NOTIFICATIONS) {
		//     this.UPDATE_ALL_NOTIFICATIONS_PREFERENCES(params)
		//   }
		//
		//   if (params.category_id !== GET_ALL_NOTIFICATIONS) {
		//     this.UPDATE_NOTIFICATION_PREFERENCES(params)
		//   }
		//
		//   return await this.$repository.v3.match.profile.putNotificationsPreferences(
		//     params
		//   )
		// },
	},
})
