import { datadogLogs } from '@datadog/browser-logs'
import { gbAttributes } from '~/plugins/04.featureFlags'
import type { AuthUser } from '~/types/auth'

interface Auth extends AuthUser {
	loggedIn: boolean
}

const initialState: Auth = {
	user: null,
	loggedIn: false,
}

export const useAuthStore = defineStore('auth', {
	state: () => ({ ...initialState }),
	getters: {
		getAvatar: (state) => {
			if (!state.user) return null
			const { avatar, business } = state.user

			if (avatar) return avatar
			if (business.logo_path) return business.logo_path

			return null
		},
		getUserName: (state) => {
			if (!state.user) return null
			if (state.user.name) return state.user.name
			return state.user.business.name
		},
	},
	actions: {
		REMOVE_TOKEN() {
			const { $fetchHire, $feature } = useNuxtApp()
			const authToken = useCookie('auth.business.token')

			this.loggedIn = false
			this.user = null
			authToken.value = null
			$fetchHire.defaults.headers.common.Authorization = null

			$feature.setAttributes({})
			$feature.refreshFeatures()
		},
		ADD_TOKEN(token) {
			const { $fetchHire } = useNuxtApp()
			const authToken = useCookie('auth.business.token')

			this.loggedIn = true
			authToken.value = token
			$fetchHire.defaults.headers.common.Authorization = `Bearer ${token}`
		},
		async login(params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.hire.auth.postLogin(params)

			if (response.success) {
				this.ADD_TOKEN(response.result.access_token)
			}

			return response
		},
		async fetchMe() {
			const { $repository, $feature, $subscription } = useNuxtApp()

			const response = await $repository.v3.hire.auth.getMe()

			if (response.success) {
				this.user = response.result
				await $subscription.fetchCurrentSubscription()
				$feature.setAttributes(gbAttributes(response.result))
				$feature.refreshFeatures()
				datadogLogs.setUser({
					id: response.result.id,
					email: response.result.email,
					name: response.result.name,
				})
			}

			return response
		},
	},
})
