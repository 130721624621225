import Toast, { PluginOptions } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const options: PluginOptions = {
	// We can set our default options here
	// https://github.com/Maronato/vue-toastification#toast-options-object
	timeout: 5000,
	position: 'top-right',
	transition: 'Vue-Toastification__fade',
}

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.use(Toast, options)
})
